import React from 'react'

export default function Card({ children, title, actions }) {
    return (
        <div className="card">
            {title || actions ? (
                <div className="card-header">
                    {title ? (
                        <p className="card-header-title">
                            {title}
                        </p>
                    ) : null}

                    {actions ? (
                        <div className="card-header-icon">
                            {actions}
                        </div>
                    ) : null}
                </div>
            ) : null}

            <div className="card-content">
                {children}
            </div>
        </div>
    )
}
