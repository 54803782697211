import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'

export default function Container({ children }) {
    return (
        <div className={cn('container', css(styles.container))}>
            {children}
        </div>
    )
}

const styles = StyleSheet.create({
    container: {
        paddingLeft: 25,
        paddingRight: 25,
    },
})
