import React from 'react'
import cn from 'classnames'
import Button from './common/Button'
import { useModal } from '../hooks/modal'
import { domain } from '../utils/request'
import RotationItemCreate from './RotationItemCreate'
import RotationItemUpdate from './RotationItemUpdate'

export default function RotationItemItem({ reportItems, finance, rotationId, index, request }) {
    const reportItem = reportItems.filter((item) => item.finance === finance.id)[0]

    const [showModal, hideModal] = useModal(
        <RotationItemCreate
            onSuccess={() => {
                hideModal()
                request()
            }}
            rotationId={rotationId}
            finance={finance}
            onCancel={() => hideModal()} />,
    )

    const [showUpdateModal, hideUpdateModal] = useModal(
        <RotationItemUpdate
            onSuccess={() => {
                hideUpdateModal()
                request()
            }}
            reportItem={reportItem}
            finance={finance}
            rotationId={rotationId}
            onCancel={() => hideModal()} />,
    )

    function onSubmit() {
        if (reportItem) {
            showUpdateModal()
            return
        }
        showModal()
    }

    return (
        <tr className="pl-3 pr-3">
            <td className="has-text-centered">
                {index + 1}
            </td>

            {reportItem ? (
                <td className={cn('has-text-centered has-text-black')}>
                    {finance.name}
                </td>
            ) : (
                <td className={cn('has-text-centered has-text-danger')}>
                    {finance.name}
                </td>
            )}

            <td className="has-text-centered">
                {finance.stir}
            </td>

            <td className="has-text-centered">
                {finance.directorStir}
            </td>

            {reportItem ? (
                <>
                    <td className="has-text-centered">
                        <a className="has-text-black" href={domain + reportItem.rotationPdf}>
                            download
                        </a>
                    </td>

                    <td className="has-text-centered">
                        {reportItem.rotationComment}
                    </td>

                </>
            ) : (
                <>
                    <td className="has-text-centered">
                        Пусто
                    </td>
                    <td className="has-text-centered">
                        Пусто
                    </td>
                </>
            )}

            <td>
                <Button
                    onClick={onSubmit}
                    className="is-link"
                    icon="icon ion-md-add"
                    text={reportItem ? 'Обновить' : 'Добавить'}
                />
            </td>
        </tr>
    )
}
