import cn from 'classnames'
import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import Input from './common/Input'

export default function Password({ name, validate, placeholder, label, icon }) {
    const [showPassword, setShowPassword] = useState(false)

    return (
        <div className="field">
            <div className={cn('control has-icons-right', { 'has-icons-left': icon })}>
                <Input
                    name={name}
                    validate={validate}
                    label={label}
                    placeholder={placeholder}
                    type={showPassword ? 'text' : 'password'} />

                {icon ? (
                    <span className="icon is-left">
                        <i className={icon}> </i>
                    </span>
                ) : null}

                <span className={cn('icon is-right', css(styles.showPassword), { [css(styles.hasLabel)]: label })}>
                    <i
                        className={showPassword ? 'ion-md-eye-off' : 'ion-md-eye'}
                        onClick={() => setShowPassword(!showPassword)} />
                </span>
            </div>
        </div>
    )
}

const styles = StyleSheet.create({
    showPassword: {
        pointerEvents: 'auto',
        color: 'black',
    },
    hasLabel: {
        marginTop: '25px',
    },
})
