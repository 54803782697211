import { css, StyleSheet } from 'aphrodite'
import React from 'react'

export default function LayoutAuth({ children }) {
    return (
        <div className="login-wrapper columns is-gapless">
            <div className="column is-12">
                <div className="hero form-hero is-fullheight">
                    <div className="hero-body has-text-centered">
                        <div className={css(styles.formWrapper)}>
                            <div className={css(styles.container)}>
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const styles = StyleSheet.create({
    container: {
        padding: '2rem',
    },
    roundedButtons: {
        '> button': {
            borderRadius: '50%',
        },
    },
    input: {
        color: '#4a4a4a',
    },
    isPrimary: {
        backgroundColor: 'rgb(31, 38, 104) !important',
    },
    forgotPassword: {
        color: '#999',
        fontSize: '.9rem',
        ':hover': {
            color: '#0062ff',
        },
    },
    formWrapper: {
        maxWidth: '640px',
        minWidth: '480px',
        margin: '0 auto',
    },
})
