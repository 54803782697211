import React from 'react'
import { ROTATION_ITEM_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import ServerError from './common/ServerError'
import RotationItemForm from './RotationItemForm'

export default function RotationItemUpdate({ onCancel, onSuccess, reportItem, finance, rotationId }) {
    const url = ROTATION_ITEM_DETAIL.replace('{id}', reportItem.id)
    const rotationItemUpdate = usePutRequest({ url })

    async function onSubmit(data) {
        const { success } = await rotationItemUpdate.request({ data })

        if (success) {
            onSuccess()
        }
    }

    return (
        <div>
            <b>Изменить данные</b><br /><br />
            <ServerError error={rotationItemUpdate.error} />
            <RotationItemForm
                initialValues={reportItem}
                onSubmit={onSubmit}
                onCancel={onCancel}
                rotationId={rotationId}
                finance={finance}
                loading={rotationItemUpdate.loading} />
        </div>
    )
}
