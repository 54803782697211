import React from 'react'
import { NavLink } from 'react-router-dom'
import Card from './common/Card'
import Button from './common/Button'
import Progress from './common/Progress'
import { useDeleteRequest } from '../hooks/request'
import { ROTATION_DETAIL } from '../urls'
import { useMessage } from '../hooks/message'
import { useModal } from '../hooks/modal'
import RotationUpdate from './RotationUpdate'

export default function RotationItem({ reportItem, onUpdate, onDelete }) {
    const rotationDelete = useDeleteRequest({
        url: ROTATION_DETAIL.replace('{id}', reportItem ? reportItem.id : ''),
    })
    const [showUpdateModal, hideUpdateModal] = useModal(
        <RotationUpdate report={reportItem} onUpdate={() => {
            onUpdate()
            hideUpdateModal()
        }} onCancel={() => {
            hideUpdateModal()
        }} />,
    )

    const [showMessage] = useMessage()

    async function deleteReport() {
        if (!global.confirm('Вы действительно хотите удалить?')) return

        const { error } = await rotationDelete.request()
        if (error) {
            showMessage(error.data.detail, 'is-danger')
            return
        }
        onDelete()
    }

    return (

        <Card title={reportItem.date} actions={(
            <div>
                <i className="icon ion-md-trash" onClick={deleteReport} />
                <i className="icon ion-md-create" onClick={showUpdateModal} />
            </div>
        )}>

            <div className="card-content">
                <div className="level">
                    <span className="level-left">Мижозлар сони:</span>
                    <span className="level-right">{reportItem.clientCount}</span>
                </div>

                <div className="level">
                    <span className="level-left">Топширганлар сони:</span>
                    <span className="level-right">{reportItem.taskCount}</span>
                </div>

                <Progress value={reportItem.progress} text={`${reportItem.progress} %`} />
            </div>

            <NavLink to={`/rotation-item/${reportItem.id}`} className="has-text-white">
                <Button
                    className="is-link table is-striped is-fullwidth card-content"
                    text="Ҳисобот юбориш"
                    icon="ion-md-cloud-upload"
                />
            </NavLink>
        </Card>

    )
}
