import React from 'react'
import { usePostRequest } from '../hooks/request'
import { QQC_MONTHLY_ITEM_LIST } from '../urls'
import QqcMonthlyItemForm from './QqcMonthlyItemForm'

export default function QqcMonthlyItemCreate({ onCancel, onSuccess, finance, qqcMonthlyId }) {
    const monthCreate = usePostRequest({ url: QQC_MONTHLY_ITEM_LIST.replace('{qqcMonthlyId}', finance.id) })

    async function onSubmit(data) {
        const { success } = await monthCreate.request({ data })

        if (success) {
            onSuccess()
        }
    }

    return (
        <div>
            <b>Добавить</b><br /><br />
            <QqcMonthlyItemForm
                finance={finance}
                qqcMonthlyId={qqcMonthlyId}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={monthCreate.loading} />
        </div>
    )
}
