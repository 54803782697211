export const SIGNIN = '/users/sign-in'
export const SIGNOUT = '/users/sign-out'

// Finance
export const FINANCE_LIST = 'diagnosis/finance/'
export const FINANCE_DETAIL = 'diagnosis/finance/{id}'

// Rotation
export const ROTATION_LIST = 'diagnosis/rotation/'
export const ROTATION_DETAIL = 'diagnosis/rotation/{id}'

// Rotation Item
export const ROTATION_ITEM_LIST = 'diagnosis/rotation/{rotationId}/rotation-item'
export const ROTATION_ITEM_DETAIL = 'diagnosis/rotation/rotation-item/{id}'

// Qqc  Monthly
export const QQC_MONTHLY_LIST = 'diagnosis/qqc-monthly/'
export const QQC_MONTHLY_DETAIL = 'diagnosis/qqc-monthly/{id}'

// Qqc  Monthly Item
export const QQC_MONTHLY_ITEM_LIST = 'diagnosis/qqc-monthly/{qqcMonthlyId}/qqc-monthly-item'
export const QQC_MONTHLY_ITEM_DETAIL = 'diagnosis/qqc-monthly/qqc-monthly-item/{id}'

// Qqc  Quarterly
export const QQC_QUARTERLY_LIST = 'diagnosis/qqc-quarterly/'
export const QQC_QUARTERLY_DETAIL = 'diagnosis/qqc-quarterly/{id}'

// Qqc  Quarterly Item
export const QQC_QUARTERLY_ITEM_LIST = 'diagnosis/qqc-quarterly/{qqcQuarterlyId}/qqc-quarterly-item'
export const QQC_QUARTERLY_ITEM_DETAIL = 'diagnosis/qqc-quarterly/qqc-quarterly-item/{id}'

// Income Tax
export const INCOME_TAX_LIST = 'diagnosis/income-tax/'
export const INCOME_TAX_DETAIL = 'diagnosis/income-tax/{id}'

// Income Tax Item
export const INCOME_TAX_ITEM_LIST = 'diagnosis/income-tax/{incomeTaxId}/income-tax-item'
export const INCOME_TAX_ITEM_DETAIL = 'diagnosis/income-tax/income-tax-item/{id}'

// Income Quarterly
export const INCOME_QUARTERLY_LIST = 'diagnosis/income-quarterly/'
export const INCOME_QUARTERLY_DETAIL = 'diagnosis/income-quarterly/{id}'

// Income Quarterly Item
export const INCOME_QUARTERLY_ITEM_LIST = 'diagnosis/income-quarterly/{incomeQuarterlyId}/income-quarterly-item'
export const INCOME_QUARTERLY_ITEM_DETAIL = 'diagnosis/income-quarterly/income-quarterly-item/{id}'

export const TELEGRAM = 'bot/telegram-users'
