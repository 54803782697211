import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import Input from './common/Input'
import Button from './common/Button'
import Select from './common/Select'
import { positions } from '../utils/position'
import { number } from '../utils/validators'

export default function FinanceForm({ onSubmit, onCancel, loading, initialValues }) {
    const options = Object.entries(positions).map(([value, name]) => ({ id: value, name }))
    console.log(options)
    const [files, setFiles] = useState({})

    async function onSuccess(data) {
        const newData = new FormData()

        newData.append('name', data.name)
        newData.append('stir', data.stir)
        newData.append('directorName', data.directorName)
        newData.append('directorStir', data.directorStir)
        newData.append('bank', data.bank)
        newData.append('accountNumber', data.accountNumber)
        newData.append('phoneNumber', data.phoneNumber)
        newData.append('mfo', data.mfo)
        newData.append('inspector', data.inspector)
        newData.append('login', data.login)
        newData.append('type', data.type)
        newData.append('taxDate', data.taxDate)
        newData.append('contractSum', data.contractSum)
        newData.append('first', files.first)
        newData.append('second', files.second)

        onSubmit(newData)
    }

    return (
        <Formik initialValues={{
            name: '',
            stir: '',
            directorName: '',
            directorStir: '',
            bank: '',
            accountNumber: '',
            phoneNumber: '',
            mfo: '',
            inspector: '',
            login: '',
            type: 'Aylanma',
            taxDate: '',
            contractSum: '',
            ...initialValues,
        }} onSubmit={onSuccess}>
            <Form>
                <div className="columns">
                    <div className="column is-6">
                        <Input
                            name="name"
                            label="Корхона номи"
                            disabled={loading}
                            placeholder="Корхона номи" />
                    </div>
                    <div className="column is-6">
                        <Input
                            type={number}
                            name="stir"
                            label="Корхона СТИР"
                            disabled={loading}
                            placeholder="Корхона СТИР" />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <Input
                            name="directorName"
                            label="Корхона рахбари"
                            disabled={loading}
                            placeholder="Корхона рахбари" />
                    </div>
                    <div className="column">
                        <Input
                            type={number}
                            name="directorStir"
                            label="Корхона рахбари СТИР"
                            disabled={loading}
                            placeholder="Корхона рахбари СТИР" />
                    </div>
                </div>
                <div className="columns">
                    <div className="column"><Input
                        name="bank"
                        label="Банк"
                        disabled={loading}
                        placeholder="Банк" />
                    </div>
                    <div className="column">
                        <Input
                            type={number}
                            name="accountNumber"
                            label="Х/р"
                            disabled={loading}
                            placeholder="Х/р" />

                    </div>
                </div>
                <div className="columns">
                    <div className="column"><Input
                        name="mfo"
                        label="МФО"
                        disabled={loading}
                        placeholder="МФО"
                    />
                    </div>
                    <div className="column">
                        <Input
                            name="inspector"
                            label="Солик инспектори"
                            disabled={loading}
                            placeholder="Солик инспектори" />

                    </div>
                </div>
                <Input
                    type="number"
                    name="phoneNumber"
                    label="Хизмат кўрсатувчи банк оператори телефон"
                    disabled={loading}
                    placeholder="Введите номер телефон" />

                <div className="columns">
                    <div className="column">
                        <Input
                            name="login"
                            label="Интернет банк логин"
                            disabled={loading}
                            placeholder="Интернет банк логин" />
                    </div>
                    <div className="column">
                        <Select
                            name="type"
                            options={options}
                            label="Солиқ тури"
                            loading={loading}
                            empty
                        />
                    </div>
                </div>

                <div className="columns">
                    <div className="column">
                        <Input
                            type="date"
                            className="level-left"
                            name="taxDate"
                            label="ҚҚСга ўтган ой"
                            disabled={loading}
                            placeholder="ҚҚСга ўтган ой" />
                    </div>
                    <div className="column">
                        <Input
                            type="number"
                            className="level-right"
                            name="contractSum"
                            label="Сумма контракта"
                            disabled={loading}
                            placeholder="0" />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <p className="level-left">
                            Корхонанинг ЭРИ калити
                        </p>

                        <input
                            onChange={(e) => setFiles({ ...files, first: e.target.files[0] })}
                            type="file"
                            disabled={loading} />
                    </div>

                    <div className="column">
                        Раҳбарнинг ЭРИ калити
                        <input
                            onChange={(e) => setFiles({ ...files, second: e.target.files[0] })}
                            type="file"
                            disabled={loading} />
                    </div>
                </div>

                <Button
                    loading={loading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-link" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Отмена"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
