import React from 'react'
import { INCOME_TAX_ITEM_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import ServerError from './common/ServerError'
import IncomeTaxItemForm from './IncomeTaxItemForm'

export default function IncomeTaxItemUpdate({ onCancel, onSuccess, reportItem, finance, incomeTaxId }) {
    const url = INCOME_TAX_ITEM_DETAIL.replace('{id}', reportItem.id)
    const incomeTaxItemUpdate = usePutRequest({ url })

    async function onSubmit(data) {
        const { success } = await incomeTaxItemUpdate.request({ data })

        if (success) {
            onSuccess()
        }
    }

    return (
        <div>
            <b>Изменить данные</b><br /><br />
            <ServerError error={incomeTaxItemUpdate.error} />
            <IncomeTaxItemForm
                initialValues={reportItem}
                onSubmit={onSubmit}
                onCancel={onCancel}
                incomeTaxId={incomeTaxId}
                finance={finance}
                loading={incomeTaxItemUpdate.loading} />
        </div>
    )
}
