import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import Input from './common/Input'
import Button from './common/Button'

export default function QqcMonthlyItemForm({ onCancel, loading, initialValues, qqcMonthlyId, finance, onSubmit }) {
    const [images, setImages] = useState({})

    async function onSuccess(data) {
        const newData = new FormData()

        newData.append('qqcMonthlyPdf', images.qqcMonthlyPdf)
        newData.append('finance', finance.id)
        newData.append('qqcMonthly', qqcMonthlyId)
        newData.append('qqcMonthlyComment', data.qqcMonthlyComment)

        onSubmit(newData)
    }

    return (
        <Formik onSubmit={onSuccess} initialValues={{ qqcMonthlyComment: '', ...initialValues }}>
            <Form>
                <div className="columns">
                    <div className="column">
                        <Input
                            name="qqcMonthlyComment"
                            label="Комментарий"
                            disabled={loading}
                            placeholder="Комментарий" />
                    </div>

                </div>

                <div className="columns">
                    <div className="column">
                        <p className="level-left">
                            ҚҚС Ойлик
                        </p>

                        <input
                            onChange={(e) => setImages({ ...images, qqcMonthlyPdf: e.target.files[0] })}
                            type="file"
                            disabled={loading} />
                    </div>

                </div>

                <Button
                    loading={loading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-link" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Отмена"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
