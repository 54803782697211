import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import NotFound from './pages/NotFound'
import Finance from './pages/Finance'
import { getEnv } from './utils/environment'
import BaseContextWrapper from './components/common/BaseContext'
import { GlobalProvider } from './contexts/GlobalContext'
import Login from './pages/Login'
import Rotation from './pages/Rotation'
import ProtectedRoute from './components/common/ProtectedRoute'
import QqcMonthly from './pages/QqcMonthly'
import QqcQuarterly from './pages/QqcQuarterly'
import UploadRotation from './pages/UploadRotation'
import UploadQqcMonthly from './pages/UploadQqcMonthly'
import UploadQqcQuarterly from './pages/UploadQqcQuarterly'
import UploadIncomeTax from './pages/UploadIncomeTax'
import IncomeTax from './pages/IncomeTax'
import IncomeQuarterly from './pages/IncomeQuarterly'
import UploadIncomeQuarterly from './pages/UploadIncomeQuarterly'
import Telegram from './pages/Telegram'

export default function App() {
    useEffect(() => {
        if (getEnv() === 'production') return
        document.title = `${getEnv().toUpperCase()} ${document.title}`
    }, [])

    return (
        <GlobalProvider>
            <BrowserRouter>
                <BaseContextWrapper>
                    <Switch>
                        <Route path="/" component={Login} exact />
                        <ProtectedRoute path="/income-tax" component={IncomeTax} exact />
                        <ProtectedRoute path="/income-tax-item/:incomeTaxId" component={UploadIncomeTax} exact />
                        <ProtectedRoute path="/income-quarterly" component={IncomeQuarterly} exact />
                        <ProtectedRoute path="/income-quarterly-item/:incomeQuarterlyId"
                            component={UploadIncomeQuarterly} exact />
                        <ProtectedRoute path="/clients" component={Finance} exact />
                        <ProtectedRoute path="/rotation" component={Rotation} exact />
                        <ProtectedRoute path="/rotation-item/:rotationId" component={UploadRotation} exact />
                        <ProtectedRoute path="/qqc-monthly" component={QqcMonthly} exact />
                        <ProtectedRoute path="/qqc-monthly-item/:qqcMonthlyId" component={UploadQqcMonthly} exact />
                        <ProtectedRoute path="/qqc-quarterly" component={QqcQuarterly} exact />
                        <ProtectedRoute path="/qqc-quarterly-item/:qqcQuarterlyId" component={UploadQqcQuarterly}
                            exact />
                        <ProtectedRoute path="/telegram" component={Telegram} exact />
                        <Route path="" component={NotFound} exact />
                    </Switch>
                </BaseContextWrapper>
            </BrowserRouter>
        </GlobalProvider>
    )
}
