export const positions = {
    rotation: 'Айланма',
    qqc_monthly: 'ҚҚc-ойлик',
    qqc_quarterly: 'ҚҚc-чораклик',
}

export function position(name) {
    return positions[name]
}

// export const positions = [
//     { name: 'Айланма', id: 'rotation' },
//     { name: 'ҚҚc-ойлик', id: 'qqc_monthly' },
//     { name: 'ҚҚc-чораклик', id: 'qqc_quarterly' },
// ]
