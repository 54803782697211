import React from 'react'
import Button from './common/Button'
import { getDate } from '../utils/date'

export default function TelegramItem({ item, index }) {
    return (
        <tr>
            <td className="has-text-center">
                {index + 1}
            </td>
            <td className="has-text-center">
                {item.username}
            </td>
            <td className="has-text-center">
                {item.car}
            </td>
            <td className="has-text-center has-text-success">
                <i className="ion-md-paper-plane pr-2" />
                {item.number}
            </td>
            {/*<td className="has-text-center">*/}
            {/*    {item.colorOfCar}*/}
            {/*</td>*/}
            <td className="has-text-center">
                <i className="ion-md-phone-portrait pr-2" />

                {item.numberOfCar}
            </td>
            <td className="has-text-center">
                {getDate(item.lastActivity)}
            </td>

            <td className="has-text-center">
                <Button className="is-link" icon="icon ion-md-archive" text="Archived" />
            </td>
        </tr>
    )
}
