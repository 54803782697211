import React from 'react'
import { usePostRequest } from '../hooks/request'
import { ROTATION_ITEM_LIST } from '../urls'
import RotationItemForm from './RotationItemForm'

export default function RotationItemCreate({ onCancel, onSuccess, finance, rotationId }) {
    const monthCreate = usePostRequest({ url: ROTATION_ITEM_LIST.replace('{rotationId}', finance.id) })

    async function onSubmit(data) {
        const { success } = await monthCreate.request({ data })

        if (success) {
            onSuccess()
        }
    }

    return (
        <div>
            <b>Добавить</b><br /><br />
            <RotationItemForm
                finance={finance}
                rotationId={rotationId}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={monthCreate.loading} />
        </div>
    )
}
