import React from 'react'
import { INCOME_TAX_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import ServerError from './common/ServerError'
import IncomeTaxForm from './IncomeTaxForm'

export default function IncomeTaxUpdate({ onCancel, onUpdate, report }) {
    const url = INCOME_TAX_DETAIL.replace('{id}', report.id)
    const rotationUpdate = usePutRequest({ url })

    async function onSubmit(data, actions) {
        const { success } = await rotationUpdate.request({ data })

        if (success) {
            actions.resetForm()
            onUpdate()
        }
    }

    return (
        <div>
            <b>Изменить данные о враче</b><br /><br />
            <ServerError error={rotationUpdate.error} />
            <IncomeTaxForm
                initialValues={report}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={rotationUpdate.loading} />
        </div>
    )
}
