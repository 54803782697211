import React from 'react'
import { usePostRequest } from '../hooks/request'
import { QQC_MONTHLY_LIST } from '../urls'
import QqcMonthlyForm from './QqcMonthlyForm'

export default function QqcMonthlyCreate({ onCancel, onSuccess }) {
    const qqcMonthlyCreate = usePostRequest({ url: QQC_MONTHLY_LIST })

    async function onSubmit(data, actions) {
        const { success } = await qqcMonthlyCreate.request({ data })

        if (success) {
            onSuccess()
            actions.resetForm()
        }
    }

    return (
        <div>
            <b>Добавить</b><br /><br />
            <QqcMonthlyForm onSubmit={onSubmit} onCancel={onCancel} loading={qqcMonthlyCreate.loading} />
        </div>
    )
}
