import React, { createContext, useState } from 'react'
import { usePersistState } from '../hooks/state'

export const GlobalContext = createContext()

export function GlobalProvider({ children }) {
    const [lang, setLang] = usePersistState('language', 'ru')
    const [carts, setCarts] = useState(JSON.parse(localStorage.getItem('carts') || '[]'))
    const [cartCount, setCartCount] = useState(JSON.parse(localStorage.getItem('cartCount') || 0))
    const user = JSON.parse(localStorage.getItem('user'))

    function addCart(product) {
        // eslint-disable-next-line no-restricted-syntax
        for (const cart of carts) {
            if (cart.productSale ? cart.productSale.product.id : cart.id === product.id) {
                return
            }
        }

        const newCarts = [...carts, product]
        setCarts(newCarts)
        localStorage.setItem('carts', JSON.stringify(newCarts))
        setCartCount(cartCount + 1)
        localStorage.setItem('cartCount', cartCount + 1)
    }

    return (
        <GlobalContext.Provider value={{ lang, setLang, setCarts, carts, addCart, cartCount, user, setCartCount }}>
            {children}
        </GlobalContext.Provider>
    )
}
